"use client";
import React, { useState } from "react";
import PricingCard from "./PricingCard";
import LoadingScreen from "@components/Spinner/LoadingSpinnerScreen";
import { useUserInput } from "@providers/UserInputProvider";

const PricingCards = () => {
  const { prices, pricesIsLoading } = useUserInput() as any;
  const [isAnnual, setIsAnnual] = useState(false);

  const priceDataProAnnual = prices?.find(
    (price: any) => price.lookup_key === "pro_annually"
  );

  const priceDataValuationAnnual = prices?.find(
    (price: any) => price.lookup_key === "valuation_annually"
  );

  if (pricesIsLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="flex justify-center">
      <div className="flex flex-col md:flex-row gap-8 sm:gap-8">
        {priceDataProAnnual && (
          <div>
            <PricingCard
              productName={priceDataProAnnual.productName}
              productTagline="Full one-year access to our forecast and valuation platform"
              price={{
                id: priceDataProAnnual.id, // Add id property here
                currency: priceDataProAnnual.currency,
                unitAmount: priceDataProAnnual.unit_amount,
                interval: priceDataProAnnual.recurring.interval,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCards;
