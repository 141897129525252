"use client";

import PricingCards from "@components/Pricing/PricingCards";
import SmallTitle from "../../../components/Public/Titles/SmallTitle";
import MainTitle from "../../../components/Public/Titles/MainTitle";
import { cn } from "@lib/utils";

const Pricing = ({ className }: any) => {
  return (
    <section className={cn("  ", className)}>
      <div className="max-w-7xl w-full  ">
        <div className="mx-auto lg:text-center">
          <SmallTitle>Pricing</SmallTitle>

          <MainTitle>Get started now</MainTitle>
        </div>
        <PricingCards />
      </div>
    </section>
  );
};

export default Pricing;
