"use client";
import React from "react";
import SignInButton from "@components/Public/SignIn/SignInButton";
import { ReactTyped } from "react-typed";
import SmallTitle from "@components/Public/Titles/SmallTitle";
import Image from "next/image";
import { useCases2 as useCases } from "@constants/staticPages/useCases";
import { cn } from "@lib/utils";
import LeadText from "@components/Text/LeadText";
import { textClassName } from "./utils";
const Hero = ({ className }: any) => {
  return (
    <section className={cn("", className)}>
      <div className="max-w-7xl w-full   ">
        <div className="flex flex-col sm:flex-row items-center gap-8 ">
          <div className="sm:w-1/2">
            <SmallTitle className="hidden sm:block mx-0 ">
              Plan ahead
            </SmallTitle>

            <h1 className=" text-3xl lg:text-4xl leading-snug lg:leading-tight text-white  ">
              Forecast your business for financial planning and/or valuation
            </h1>

            <LeadText>
              Our platform provides hands-on users a professional framework to
              determine the cash flow forecast and valuation of a business.
            </LeadText>
            <div className="flex flex-wrap justify-center items-center gap-x-2 gap-y-0 my-4"></div>
            <div className=" ">
              <SignInButton size="lg" label="Get started" />
            </div>
          </div>
          <div className="sm:w-1/2 mt-10 sm:mt-0">
            <Image
              src={"/images/undraw/undraw-contro-panel.svg"}
              alt="Hero image"
              width={1000}
              height={1000}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
