// LeadText.js or LeadText.tsx
import React, { PropsWithChildren } from "react";
import { cn } from "@lib/utils";
import { textClassName } from "@app/(public)/(home)/utils";

const LeadText = ({ children }: PropsWithChildren) => {
  return (
    <p className={cn(textClassName, " text-base lg:text-base py-6 text-white")}>
      {children}
    </p>
  );
};

export default LeadText;
