import React from "react";
import { cn } from "@lib/utils";

const MainTitle = ({
  children,
  as = "h2", // default to h2 if 'as' prop is not provided
  className,
}: {
  children: React.ReactNode;
  as?: "h1" | "h2"; // restrict to either h1 or h2
  className?: string;
}) => {
  const Tag = as; // set the tag based on the 'as' prop

  return (
    <Tag
      className={cn(
        "md:text-4xl sm:text-3xl text-2xl   mb-6 sm:mb-10 lg:mb-20 text-gray-800 leading-snug lg:leading-tight",
        className
      )}
    >
      {children}
    </Tag>
  );
};

export default MainTitle;
