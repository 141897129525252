import React from "react";
import { cn } from "@lib/utils";

const SmallTitle = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <h3
      className={cn(
        "uppercase text-main font-medium     rounded-full mb-4 text-sm  mx-auto w-max   py-1  ",
        className
      )}
    >
      {children}
    </h3>
  );
};

export default SmallTitle;
